//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$accent-primary-dark: #BA1D2F;
$accent-primary-light: #EE2D41;
$accent-secondary-dark: #F64E60;
$accent-secondary-light: #FEE7DD;

$gradient-primary: linear-gradient(139.68deg, $accent-primary-dark -8.52%, $accent-primary-light 80.05%);; 

$primary: $accent-primary-dark;
$primary-hover: $accent-primary-light;
$primary-light: $accent-secondary-light;
$primary-inverse: #FFFFFF;

$success-light: #c0ebe8 !default;
$link-hover-color: $accent-secondary-dark;

$table-compact-head-color: #3F4254;